<template>
  <div class="guide-container">
    <h1>
      How to transfer from binance to another wallet address
    </h1>

    <br />
    <h2 class="second-colour">
      What is a crypto wallet?
    </h2>

    <p>
      A cryptocurrency wallet is a digital wallet that enables you to securely
      store, manage, and transfer various cryptocurrencies such as Bitcoin,
      Ethereum, and others. It acts as an interface between you and the
      blockchain network, allowing you to view your balance, send and receive
      funds, and check transaction history.
    </p>
    <br />
    <h2 class="second-colour">
      Trust Wallet
    </h2>
    <p>
      This guide uses the Trust Wallet app as an example, and the Binance
      website to transfer Bitcoin. However the same approach can be used for any
      cryptocurrency and crypto wallet.
    </p>

    <br />

    <h2 class="second-colour">
      Steps
    </h2>

    <ol class="padding-left-0">
      <!-- Start of ordered list -->

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li>
        First you will need to download a crypto wallet app. Navigate to the
        Trust Wallet iOS or Google Play app store pages using the links
        <a href="https://trustwallet.com/download/" target="_blank">here</a>.
        Then install on your device. The screenshot below shows an example of
        the Trust Wallet page on Google Play.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/1.png"
        alt="The Trust Wallet Google Play app store page"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Once downloaded, open your crypto wallet app.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/2.png"
        alt="Google Play Trust Wallet open app page"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        If this is your first cryto wallet, click on 'Create a new wallet'.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/3.png"
        alt="Trust wallet first page showing create a new wallet button"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click the checkbox to accept the terms of service. Click 'Continue'.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/4.png"
        alt="Trust wallet legal page showing privacy policy and terms of service with continue button"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Tick the 3 checkboxes. Click 'Continue'.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/6.png"
        alt="Trust wallet secret phrase recovery notice page with continue button"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Write down or copy these words in the right order and save them
        somewhere safe. Click 'Copy' if you would like to send them to yourself
        as a message. Click 'Continue'.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/7.png"
        alt="Trust wallet your secret phrase screen show 12 words to remember"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Tap the words to put them next to each other in the correct order. Click
        'Done'.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/8.png"
        alt="Trust wallet secret phrase put words in correct order page"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        The next 4 screens give an overview of the Trust Wallet functionality.
        These can be skipped by clicking the 'X' in the top right.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/9.png"
        alt="Trust wallet lets explore your wallet guide screen"
      />

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/10.png"
        alt="Trust wallet buy crypto new user guide screen with buy now button"
      />

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/11.png"
        alt="Trust wallet new user deposit NFTs guide screen with deposit now button"
      />

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/12.png"
        alt="Trust wallet new user deposit crypto guide screen with deposit now button"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        You can enable push notifications from Trust Wallet by clicking 'Enable'
        or dismiss by clicking the 'X' in the upper right.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/13.png"
        alt="Trust wallet android app enable push notifications pop up"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on 'Receive'.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/14.png"
        alt="Trust wallet android app main screen"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Select the currency you would like to receive. In the example here
        Bitcoin has been chosen, however you can choose any cryptocurrency.
      </li>

      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/15.png"
        alt="Trust wallet receive crypto selection screen"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        In the next screen a QR code and wallet address will appear. Click
        'Copy' and send the code to yourself as a message / email, or write down
        the address. This is so you can copy the address into your Binance
        account later. Note that you can only send the cryptocurrency you
        selected to this address. In the example here only BTC (Bitcoin) can be
        sent to this address.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/16.png"
        alt="Trust wallet receive BTC QR code and address screen with copy button"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Next click
        <a href="https://accounts.binance.com/en/login" target="_blank">here</a>
        to login to your Binance account. Click on 'Wallet', then 'Fiat And
        Spot'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/17.png"
        alt="Binance main screen showing Wallet and Fiat And Spot menu"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Search for the crypto coin you would like to send to your wallet, or
        scroll down to find it. Next to coin click on 'Withdraw'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/18.png"
        alt="Binance Fiat And Spot screen with bitcoin withdraw button"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Check that the correct coin has been selected. Paste the address from
        your crypto wallet into the address field. Select the network. Enter the
        amount you would like to withdraw, you can click on 'Max' to withdraw
        all of your selected currency. Be sure to check the network fee and
        recieve amount at the bottom. If all looks good, click 'Withdraw'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/19.png"
        alt="Binance withdraw crypto screen showing wallet address, network, fee, and amount"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Check the details in the confirmation popup. Click 'Continue'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/20.png"
        alt="Binance withdrawl confirmation popup box"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click on 'Get Code' in the two fields. You will receive a phone text
        message and an email with the two codes.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/21.png"
        alt="Binance withdrawl security verification popup"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Once you've received the two codes to your phone and email, enter them
        into the boxes. Click 'Submit'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/22.png"
        alt="Binance security verification popup with two codes entered"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        You have the option to save your wallet address in your Binance account.
        Give the address a label such as "My Wallet", select the 'Address
        origin', and click 'Save'. Or if you'd prefer not to save the address
        click 'Cancel' and skip to step 23 here.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/23.png"
        alt="Binance save wallet address popup"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        If you're withdrawing currency to a wallet click on 'Wallet', if not you
        can select 'Exchange'. Click 'Ok'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/24.png"
        alt="Binance new wallet address origin popup"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Click 'Save'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/25.png"
        alt="Binance new wallet save address popup with save button highlighted"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        To confirm the save of the new wallet address you will need to go
        through an additional security check. Click 'Get Code'. You will then
        receive a text message on your phone. Enter the code in the box and then
        click 'Submit'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/26.png"
        alt="Binance enter code from phone number verification popup"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Scroll down to the bottom of the screen and you will see the withdraw
        details showing and the 'Processing' status. The transfer to your crypto
        wallet can take several minutes.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/27.png"
        alt="Binanace withdraw screen show recent withdraws and the processing status"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Go back to your crypto wallet app on your phone. After a few minutes you
        should see the new amount. In the example below you can see the new
        amount at the top of £16.22. Click on the 'Bitcoin' row, or whichever
        currency you transferred.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/28.png"
        alt="Trust wallet main screen showing updated amount received"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Here you can see the total amount of the currency along with the current
        converted amount to fiat currency.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/29.png"
        alt="Trust wallet BTC bitcoin detail screen"
      />

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Refresh your Binanace page and you should now see the status as
        'Completed'.
      </li>
      <img
        class="margin-top-20px"
        src="@/assets/imgs/Guides/Binance/HowToTransferFromBinanceToAnotherWalletAddress/30.png"
        alt="Binance withdraw screen showing completed status"
      />

    </ol>
      <!-- End of Steps ordered list -->

      <br />
      <br />
      <h2 class="second-colour">
        Crypto transferred successfully
      </h2>

      <p>
        Congratulations. You've now transferred crypto from Binance to your
        crypto wallet.
      </p>
  </div>
</template>
